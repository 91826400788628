exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-change-password-tsx": () => import("./../../../src/pages/ChangePassword.tsx" /* webpackChunkName: "component---src-pages-change-password-tsx" */),
  "component---src-pages-forget-password-tsx": () => import("./../../../src/pages/ForgetPassword.tsx" /* webpackChunkName: "component---src-pages-forget-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/Login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-my-package-tsx": () => import("./../../../src/pages/MyPackage.tsx" /* webpackChunkName: "component---src-pages-my-package-tsx" */),
  "component---src-pages-packagedetails-tsx": () => import("./../../../src/pages/Packagedetails.tsx" /* webpackChunkName: "component---src-pages-packagedetails-tsx" */),
  "component---src-pages-pay-out-tsx": () => import("./../../../src/pages/PayOut.tsx" /* webpackChunkName: "component---src-pages-pay-out-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/Profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/Register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/ResetPassword.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-sales-tsx": () => import("./../../../src/pages/Sales.tsx" /* webpackChunkName: "component---src-pages-sales-tsx" */)
}

